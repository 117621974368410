import { urlForImage } from 'utils/imageUtils';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import theme from 'theme';

const ImageContainer = styled.div`
  ${theme.breakpoints.down('sm')} {
    gap: ${theme.spacing(1)};
    width: 100%;
    margin: ${theme.spacing(3, 0)};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(3)};
  margin: ${theme.spacing(5, 0)};
`;

const Image = styled.img`
  object-fit: contain;
  max-width: 100%;
  aspect-ratio: auto;
  margin: auto;
  max-height: auto;
`;

const ImageWidgetRenderer = ({
  value: {
    image,
    alt,
  },
}) => {
  const width = useMemo(() => {
    if (typeof window === 'undefined') {
      return 1200;
    }
    return window.innerWidth;
  }, []);

  if (!image) {
    return null;
  }
  return (
    <ImageContainer>
      <Image
        src={
          urlForImage(image)
            .width(width)
            .url()}
        alt={alt}
      />
    </ImageContainer>
  );
};
export default ImageWidgetRenderer;

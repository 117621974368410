import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import React, { ForwardRefRenderFunction } from 'react';
import styled from '@emotion/styled';

const Content = styled.div<{
  $isFullWidth,
  $isRow,
  $isRowTablet,
  $center
  $alignStart
}>(({
  theme,
  $isFullWidth,
  $isRow,
  $isRowTablet,
  $center,
  $alignStart,
}) => css`
  ${theme.breakpoints.down('lg')} {
    flex-direction: ${$isRowTablet ? 'row' : 'column'};
    max-width: ${$isFullWidth ? '100%' : '855px'};
  }
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    max-width: 100%;
  }
  display: flex;
  position: relative;
  height: auto;
  min-height: 65vh;
  width: 100%;
  max-width: 1200px;
  flex-direction: ${$isRow ? 'row' : 'column'};
  gap: ${$isRow ? '40px' : '0px'};
  justify-content: ${$center ? 'center' : 'flex-start'};
  align-items: ${$alignStart ? 'flex-start' : 'center'};
  margin: auto;
  padding-bottom: 80px;
  scroll-behavior: smooth;
`);

interface ContentContainerProps {
  style?: React.CSSProperties;
  isRow?: boolean;
  isRowTablet?: boolean;
  center?: boolean;
  alignStart?: boolean;
  isFullWidth?: boolean;
}

const Container: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<ContentContainerProps>> = (
  {
    children,
    style,
    isRow,
    isRowTablet,
    center,
    alignStart,
    isFullWidth,
  }: PropsWithChildren<ContentContainerProps>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  return <Content
    ref={ref}
    $isFullWidth={isFullWidth}
    $isRow={isRow}
    $isRowTablet={isRowTablet}
    $center={center}
    $alignStart={alignStart}
    id="content-container"
    style={style}
    data-test-id="content-container">
    {children}
  </Content>;
};

const ContentContainer = React.forwardRef(
  Container
);

ContentContainer.displayName = 'ContentContainer';

export default ContentContainer;
